import { createGlobalStyle } from "styled-components"

const SharedStyles = createGlobalStyle`
    html{
        scroll-behavior: smooth;
        font-size: 16px;
        --c-mint-100: #00e2c1;
        --c-green-200: #00c3a6;
        --c-blue-100: #12171F; 
        --c-blue-101: #131c2d; 
        --c-blue-200: #0d1016; 
        --c-blue-201: #0a0e15; 
        --c-black: #000; 
        --c-white: #d7d7d7; 

        @media (min-width: 1900px) {
            font-size: 20px;
        }

        @media (min-width: 1500px) {
            font-size: 18px;
        }

        @media (min-width: 1919px) {
            font-size: 24px;
        }

        @media (max-width: 1199px) {
            font-size: 12px;
        }

        @media (max-width: 766px) {
            font-size: 15px;
        }

        @media (prefers-color-scheme: light){
            --c-white: #f2f2f2; 
        }

        &.is-light {
            --c-white: #0d1016 !important; 
        }
    }
     body {
        -webkit-font-smoothing: antialiased;
        font-family: var(--xn-font-body);
        font-weight: 400;
        background: var(--c-blue-200);
        color: var(--c-white); 

        @media (prefers-color-scheme: light){
            background: var(--c-white); 
            color: var(--c-blue-200);
        }
    }

    h1,h2{
        font-family: var(--xn-font-title);
        font-weight: 700;
    }

    .gatsby-image-wrapper {
        height: 100%; 
    }
`
export default SharedStyles
